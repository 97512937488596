import React, { Component } from "react";
import * as PropTypes from "prop-types";
import {
	Button, Col,
	DatePicker,
	Drawer,
	Form,
	Input, InputNumber,
	message,
	Modal, Row,
	Select,
	Switch,
	Tag,
	TimePicker,
	Typography
} from "antd";

import { API_ERRO_TYPE_VALIDATION } from "./../../config/general";

import { FORM_VALIDATION_MESSAGES } from "./../../config/lang";

import { pushGeneralService } from "./../../redux/services";

const FormItem = Form.Item;

const repeatTypes = [
	{
		key: 2,
		value: 'Mensalmente',
	},
	{
		key: 1,
		value: 'Semanalmente',
	},
	{
		key: 0,
		value: 'Diáriamente',
	},
];

const weekDays = [
	{
		key: 1,
		value: 'Segunda-Feira',
	},
	{
		key: 2,
		value: 'Terça-Feira',
	},
	{
		key: 3,
		value: 'Quarta-Feira',
	},
	{
		key: 4,
		value: 'Quinta-Feira',
	},
	{
		key: 5,
		value: 'Sexta-Feira',
	},
	{
		key: 6,
		value: 'Sábado',
	},
	{
		key: 0,
		value: 'Domingo',
	},
];

class Create extends Component {
	static propTypes = {
		visible   : PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose   : PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isSending: false,
			repeat: false,
			repeatType: 2,
		};
	}

	fieldOptions = {
		title       : {
			label    : "Título",
			decorator: {
				initialValue: "",
			},
		},
		body        : {
			label    : "Mensagem",
			decorator: {
				rules: [
					{required: true, message: "Campo obrigatório."},
				],
			},
		},
		url         : {
			label    : "Url",
			decorator: {
				initialValue: "",
			},
		},
		scheduled_at: {
			label    : "Agendado para",
			decorator: {},
		},
		repeat : {
			label	 : "Repetir",
			decorator: {
				valuePropName: "checked",
				initialValue: false,
			}
		},
		repeatType : {
			label	: "Qual frequência?",
			decorator: {
				initialValue: 2,
			}
		},
		hour : {
			label : "Defina a hora!"
		},
		weekDay : {
			label : "Defina o dia da semana!"
		},
		day : {
			label : "Defina o dia!"
		}
	};

	onOpen = () => {
	};

	resetFields = () => {
		this.props.form.resetFields();
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onSubmit = (e) => {
		e.preventDefault();

		this.props.form.validateFieldsAndScroll((error, values) => {
			if( !error )
			{
				this.setState({
					isSending: true,
				});

				debugger;

				let data = {...values};

				if( data.scheduled_at )
				{
					data.scheduled_at = data.scheduled_at.format("YYYY-MM-DDTHH:mm:ssZ");
				}

				if(data.hour){
					data.hour = data.hour.format('HH:mm');
				}

				pushGeneralService.create(data)
				.then((response) => {
					this.setState({
						isSending: false,
					});

					// Reset fields
					this.resetFields();

					// Success message
					message.success("Registro cadastrado com sucesso.");

					// Callback
					this.props.onComplete();
				})
				.catch((data) => {
					this.setState({
						isSending: false,
					});

					// if validation error
					if( data.error_type === API_ERRO_TYPE_VALIDATION )
					{
						let hasFieldsErrors = false;

						for( let key in data.error_errors )
						{
							if( data.error_errors[key] && this.fieldOptions[key] )
							{
								this.props.form.setFields({
									[key]: {
										value : values[key],
										errors: [new Error(data.error_errors[key])],
									}
								});

								hasFieldsErrors = true;
							}
						}

						if( !hasFieldsErrors )
						{
							Modal.error({
								title  : "Ocorreu um erro!",
								content: data.error_message,
							});
						}
					}
					else
					{
						Modal.error({
							title  : "Ocorreu um erro!",
							content: data.error_message,
						});
					}
				});
			}
		});
	};

	setUrl = (url) => {
		this.props.form.setFieldsValue({
			url: url,
		});
	};

	onChangeRepeat = (value) => {
		this.setState({
			...this.state,
			repeat: value
		})
	}

	onChangeRepeatType = (value) => {
		this.setState({
			...this.state,
			repeatType: value
		})
	}

	render() {
		const {visible, form} = this.props;
		const {isSending, repeat, repeatType}     = this.state;

		const {getFieldDecorator} = form;

		return (
			<Drawer
				visible={visible}
				className="drawer-form"
				width={360}
				maskClosable={false}
				closable={false}
				keyboard={false}
				placement="right"
				onClose={this.onClose}>
				<Form layout="vertical" onSubmit={this.onSubmit}>
					<div className="form-header">
						<Button className="btn-close" onClick={this.onClose} icon="close" disabled={isSending} />
						<div className="ant-drawer-title">Inserir novo registro</div>
						<Button type="primary" htmlType="submit" className="btn-save" icon="check" loading={isSending}>Salvar</Button>
					</div>
					<div className="form-body">
						<FormItem label={this.fieldOptions.title.label} hasFeedback>
							{getFieldDecorator("title", this.fieldOptions.title.decorator)(
								<Input maxLength={50} />
							)}
						</FormItem>
						<FormItem label={this.fieldOptions.body.label} hasFeedback>
							{getFieldDecorator("body", this.fieldOptions.body.decorator)(
								<Input.TextArea maxLength={100} autoSize={{minRows: 3, maxRows: 6}} />
							)}
						</FormItem>
						<FormItem label={this.fieldOptions.scheduled_at.label} hasFeedback extra={<Typography>Deixe em branco para enviar agora</Typography>}>
							{getFieldDecorator("scheduled_at", this.fieldOptions.scheduled_at.decorator)(
								<DatePicker
									showTime
									format="DD/MM/YYYY HH:mm"
									style={{width: "100%"}}
								/>
							)}
						</FormItem>
						<Row>
							<Col xs={8}>
							<FormItem label={this.fieldOptions.repeat.label}>
								{getFieldDecorator("repeat", this.fieldOptions.repeat.decorator)(
									<Switch onChange={this.onChangeRepeat}/>
								)}
							</FormItem>
							</Col>
							{repeat &&
								<Col xs={16}>
									<FormItem label={this.fieldOptions.repeatType.label} hasFeedback>
										{getFieldDecorator(
											"repeatType",
											this.fieldOptions.repeatType.decorator
										)(
											<Select
												onChange={this.onChangeRepeatType}
											>
												{repeatTypes.map(item => (
													<Select.Option key={item.key} value={item.key}>{item.value}</Select.Option>
												))}
											</Select>
										)}
									</FormItem>
								</Col>
							}
						</Row>
						{repeat &&
							<Row gutter={26}>
								{repeatType == 1 &&
									<Col xs={13}>
										<FormItem label={this.fieldOptions.weekDay.label} hasFeedback>
											{getFieldDecorator(
												"weekDay",
												this.fieldOptions.weekDay.decorator
											)(
												<Select>
													{weekDays.map(item => (
														<Select.Option key={item.key} value={item.key}>{item.value}</Select.Option>
													))}
												</Select>
											)}
										</FormItem>
									</Col>
								}
								{repeatType == 2 &&
									<Col xs={13}>
										<FormItem label={this.fieldOptions.day.label} hasFeedback>
											{getFieldDecorator(
												"day",
												this.fieldOptions.day.decorator
											)(
												<InputNumber min={1} max={30}/>
											)}
										</FormItem>
									</Col>
								}
								<Col xs={11}>
									<FormItem label={this.fieldOptions.hour.label}>
										{getFieldDecorator("hour", this.fieldOptions.hour.decorator)(
											<TimePicker format="HH:mm" />
										)}
									</FormItem>
								</Col>
							</Row>
						}
						<FormItem label={this.fieldOptions.url.label} hasFeedback>
							{getFieldDecorator("url", this.fieldOptions.url.decorator)(
								<Input />
							)}
						</FormItem>
						<div>
							<p>Utilizar página interna do app:</p>
							<Tag style={{marginBottom: 8}} onClick={() => this.setUrl("commodities-news")}>Cotações/Notícias</Tag>
							<Tag style={{marginBottom: 8}} onClick={() => this.setUrl("agronomist")}>Consulte os agrônomos</Tag>
							<Tag style={{marginBottom: 8}} onClick={() => this.setUrl("collection")}>Acervo</Tag>
							<Tag style={{marginBottom: 8}} onClick={() => this.setUrl("profile")}>Perfil</Tag>
						</div>
					</div>
				</Form>
			</Drawer>
		)
	}
}

export default Form.create({
	validateMessages: FORM_VALIDATION_MESSAGES,
})(Create);

import {apiv3} from "./../../config/api";
import {appendToFormData} from "../../helpers/form";

const basePath = "publications";

/**
 * Get all
 *
 * @param {Object} options
 * @param {number} [options.page]
 * @param {number} [options.limit]
 * @param {string} [options.search]
 * @param {string} [options.orderBy]
 *
 * @returns {Promise<T>}
 */
export const getAll = (options) => {
    const options_default = {};

    // Merge config
    options = Object.assign({}, options_default, options);

    let params = [];
    let params_qs = "";

    if (options.hasOwnProperty("page")) {
        params.push(`page=${options.page}`);
    }

    if (options.hasOwnProperty("limit")) {
        params.push(`limit=${options.limit}`);
    }

    if (options.hasOwnProperty("search")) {
        params.push(`search=${options.search}`);
    }

    if (options.hasOwnProperty("orderBy")) {
        params.push(`orderBy=${options.orderBy}`);
    }

    if (params.length) {
        params_qs = `?${params.join("&")}`;
    }

    return apiv3.get(`${basePath}${params_qs}`);
};

/**
 * Show
 *
 * @param {Object} options
 * @param {number} options.id
 *
 * @returns {Promise<T>}
 */
export const show = (options) => {
    return apiv3.get(`${basePath}/${options.id}`);
};

/**
 * Create
 *
 * @param {Object} options
 * @param {number} options.publication_type
 * @param {string} options.title
 * @param {string} options.text
 * @param {string} options.author
 * @param {boolean} options.is_active
 *     "images": [], // Array com todas as imagens (utilizar como galeria), enviar o arquivo binario NÃO ENVIAR BASE64
 *     "thumbnail": "imagem que irá aparacer na miniatura",
 *     "pdf": "Arquivo_pdf.pdf",
 *     "audio": "Arquivo_pdf.mp3", //
 *     "video": "Arquivo_pdf.mp4", //
 *     "link": "link_externo",
 *     "is_active": 1
 *
 * @returns {Promise<T>}
 */
export const create = (options) => {
    const formData = new FormData();
    for (let key in options) {
        if (options.hasOwnProperty(key)) {
            if (key === 'images') {
                for (let i = 0; i < options[key].length; i++) {
                    appendToFormData(formData, `${key}[${i}]`, options[key][i]);
                }
                continue;
            }
            appendToFormData(formData, key, options[key]);
        }
    }
    return apiv3.post(basePath, formData);
};
/**
 * Edit
 *
 * @param {Object} options
 * @param {number} options.id
 * @param {Object|null} [options.file] - file
 * @param {string} [options.name]
 * @param {string} [options.text]
 * @param {string} [options.author]
 * @param {boolean} [options.is_active]
 *
 * @returns {Promise<T>}
 */
export const edit = (options) => {
    const formData = new FormData();
    for (let key in options) {
        if (options.hasOwnProperty(key)) {
            if (key === 'images') {
                for (let i = 0; i < options[key].length; i++) {
                    appendToFormData(formData, `${key}[${i}]`, options[key][i]);
                }
                continue;
            }
            appendToFormData(formData, key, options[key]);
        }
    }

    return apiv3.put(`${basePath}/${options.id}`, formData);
};

/**
 * Delete
 *
 * @param {Object} options
 * @param {number} options.id
 *
 * @returns {Promise<T>}
 */
export const destroy = (options) => {
    return apiv3.delete(`${basePath}/${options.id}`);
};

/**
 * Export
 *
 * @param {Object} options
 * @param {string} [options.date_start]
 * @param {string} [options.date_end]
 *
 * @returns {Promise<T>}
 */
export const exportItens = (options) => {
    let params = [];
    let params_qs = "";

    if (options.hasOwnProperty("date_start")) {
        params.push(`date_start=${options.date_start}`);
    }

    if (options.hasOwnProperty("date_end")) {
        params.push(`date_end=${options.date_end}`);
    }

    if (params.length) {
        params_qs = `?${params.join("&")}`;
    }

    return apiv3.get(`${basePath}/export${params_qs}`);
};

export const getPublicationTypes = () => {
    return apiv3.get('publication_types/admin');
}

import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {Button, Col, Drawer, Form, Icon, Input, message, Modal, Row, Select, Spin, Switch, Upload} from "antd";

import {API_ERRO_TYPE_VALIDATION} from "./../../config/general";

import {FORM_VALIDATION_MESSAGES} from "./../../config/lang";

import {publicationService} from "./../../redux/services";
import TextEditor from "../../components/TextEditor";

const FormItem = Form.Item;

const config = {
	images: {
		file: {
			maxSize   : 4,
			maxFiles  : 1,
			extensions: ["jpg", "png"],
			type      : ["image/jpeg", "image/png"],
		},
		files: {
			maxSize: 4,
			maxFiles: 0,
			extensions: ["jpg", "png"],
			type: ["image/jpeg", "image/png"],
		},
		ads: {
			maxSize   : 4,
			maxFiles  : 1,
			extensions: ["jpg", "png"],
			type      : ["image/jpeg", "image/png"],
		}
	},
};

class Edit extends Component {
	static propTypes = {
		visible   : PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose   : PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			text 			   : "",
			isLoading          : true,
			isSending          : false,
			id                 : 0,
			// Images
			imagePreviewVisible: false,
			imagePreviewImage  : "",
			activePublicationType: {},
			publicationTypes: [],
			file: {},
			imageList          : {
				file: [],
				files: [],
			},
			imageListDeleted   : {
				file: [],
				files: [],
			},
			imageAdsList          : {
				images: [],
			},
			imageAdsListDeleted   : {
				images: [],
			},
		};
	}
	fieldOptionsModel = {
		title: {
			label: "Título",
			decorator: {
				rules: [],
			},
		},
		text: {
			label: "Texto",
			decorator: {
				initialValue: "",
				rules: [],
			},
		},
		author: {
			label: "Autor",
			decorator: {
				rules: [],
			},
		},
		link: {
			label: "Link",
			decorator: {
				rules: [],
			},
		},
	};
	fieldOptions = {
		publication_type: {
			label: "Tipo",
			decorator: {
				rules: [],
				readOnly: true
			},
		},
		is_active: {
			label: "Ativo",
			decorator: {
				valuePropName: "checked",
				initialValue: true,
			},
		},
	};

	onOpen = (id, publicationTypes) => {

		this.setState({
			isLoading: true,
			id       : id,
		});

		publicationService.show({id})
		.then((response) => {

			let item = response.data.data;
			item.publication_type = item.type_id||1;
			const activePublicationType = publicationTypes.find(type => type.id === item.publication_type);
			this.setState({
				isLoading: false,
			});

			const keys = Object.keys(activePublicationType);

			for(const key of keys){
				if(this.fieldOptionsModel[key]){
					if(!this.fieldOptions[activePublicationType.id]){
						this.fieldOptions[activePublicationType.id] = {};
					}
					this.fieldOptions[activePublicationType.id][key] = JSON.parse(JSON.stringify(this.fieldOptionsModel[key]));
					if(activePublicationType[key] === 1) {
						this.fieldOptions[activePublicationType.id][key].decorator.rules.push({required: true, message: "Campo obrigatório."});
					}
				}
			}

			let imageAdsList = {
				images: [],
			};

			let imageList = {
				file: [],
				files: [],
			}

			let file = {
				mp3: [],
				mp4: [],
				pdf: []
			};

			if( item.ads )
			{
				imageAdsList.images.push({
					uid   : item.id,
					name  : item.ads.split("/").pop(),
					status: "done",
					url   : item.ads,
					// Has id, is api image
					id    : item.id,
				});
			}

			if( item.banner_top )
			{
				imageAdsList.images.push({
					uid   : item.id,
					name  : item.banner_top.split("/").pop(),
					status: "done",
					url   : item.banner_top,
					// Has id, is api image
					id    : item.id,
				});
			}

			if( item.banner_foot )
			{
				imageAdsList.images.push({
					uid   : item.id,
					name  : item.banner_foot.split("/").pop(),
					status: "done",
					url   : item.banner_foot,
					// Has id, is api image
					id    : item.id,
				});
			}

			if(item.images && item.images.length){
				for(let image of item.images) {
					imageList.files.push({
						uid   : image.id,
						name  : image.file.split("/").pop(),
						status: "done",
						url   : image.file,
						// Has id, is api image
						id    : image.id,
					})
				}
			}

			if(item.audio){
				file.mp3.push({
					uid   : item.id,
					name  : item.audio.split("/").pop(),
					status: "done",
					url   : item.audio,
					// Has id, is api item.audio
					id    : item.id,
				});
			}

			if(item.video){
				file.mp4.push({
					uid   : item.id,
					name  : item.video.split("/").pop(),
					status: "done",
					url   : item.video,
					// Has id, is api item.video
					id    : item.id,
				});
			}

			if(item.pdf){
				file.pdf.push({
					uid   : item.id,
					name  : item.pdf.split("/").pop(),
					status: "done",
					url   : item.pdf,
					// Has id, is api item.pdf
					id    : item.id,
				});
			}

			this.setState({
				publicationTypes,
				activePublicationType,
				imageAdsList,
				imageList,
				file
			});

			// Fill form
			this.fillForm(item);
		})
		.catch((data) => {
			this.setState({
				isLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: data.error_message,
				onOk   : () => {
					// Force close
					return this.onClose();
				}
			});
		});
	};

	fillForm = (data) => {
		this.props.form.setFieldsValue({
			author     : data.author,
			title   : data.title,
			text     : data.text,
			link	 : data.link,
			publication_type: data.publication_type,
			is_active: data.is_active,
		});
	};

	resetFields = () => {
		this.props.form.resetFields();

		this.setState({
			imageList       : {
				file: [],
				files: []
			},
			imageAdsList: {
				images: [],
			},
			imageListDeleted: {
				file: [],
			},
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onSubmit = (e) => {
		e.preventDefault();

		this.props.form.validateFieldsAndScroll((error, values) => {
			if( !error )
			{
				this.setState({
					isSending: true,
				});

				const {id, imageList, imageAdsList, imageListDeleted, imageAdsListDeleted, file} = this.state;

				// ID
				values.id = id;

				// File
				const fileKeys = Object.keys(file);
				for(const fileKey of fileKeys){
					const k = fileKey === 'mp3' ? 'audio' : fileKey === 'mp4' ? 'video' : fileKey;
					values[k] = file[fileKey].length ? file[fileKey][0] : null;
				}

				const imageKeys =  Object.keys(imageList);
				for(const imageKey of imageKeys){
					if(!['file', 'files'].includes(imageKey)) {
						if(['thumbnail', 'ads'].includes(imageKey)){
							values[imageKey] = imageList[imageKey].length ? imageList[imageKey][0] : null;
						} else {
							values[imageKey] = imageList[imageKey].length ? imageList[imageKey] : null;
						}
					}
				}

				// Images ads
				if (imageAdsList.images.length) {
					values.ads = imageAdsList.images;
				}

				publicationService.edit(values)
				.then((response) => {
					this.setState({
						isSending: false,
					});

					// Reset fields
					this.resetFields();

					// Success message
					message.success("Registro atualizado com sucesso.");

					// Callback
					this.props.onComplete();
				})
				.catch((data) => {
					this.setState({
						isSending: false,
					});

					// if validation error
					if( data.error_type === API_ERRO_TYPE_VALIDATION )
					{
						let hasFieldsErrors = false;

						for( let key in data.error_errors )
						{
							if( data.error_errors[key] && this.fieldOptions[key] )
							{
								this.props.form.setFields({
									[key]: {
										value : values[key],
										errors: [new Error(data.error_errors[key])],
									}
								});

								hasFieldsErrors = true;
							}
						}

						if( !hasFieldsErrors )
						{
							Modal.error({
								title  : "Ocorreu um erro!",
								content: data.error_message,
							});
						}
					}
					else
					{
						Modal.error({
							title  : "Ocorreu um erro!",
							content: data.error_message,
						});
					}
				});
			}
		});
	};

	onImagePreviewClose = () => this.setState({imagePreviewVisible: false});

	onImagePreview = (type, file) => {
		this.setState({
			imagePreviewImage  : file.url,
			imagePreviewVisible: true,
		});
	};

	onImageRemove = (type, file) => {
		let imagesNew           = [...this.state.imageList[type]];
		let imageListDeletedNew = [...this.state.imageListDeleted[type]];

		const index = imagesNew.findIndex(item => file.uid === item.uid);

		if( index !== -1 )
		{
			imagesNew.splice(index, 1);

			// Has id
			if( file.id )
			{
				imageListDeletedNew.push(file.id);
			}

			this.setState(state => ({
				imageList       : {
					...state.imageList,
					[type]: imagesNew,
				},
				imageListDeleted: {
					...state.imageListDeleted,
					[type]: imageListDeletedNew,
				},
			}));
		}
	};

	onImageAdsPreviewClose = () => this.setState({imageAdsPreviewVisible: false});

	onImageAdsPreview = (type, file) => {
		this.setState({
			imageAdsPreviewImage  : file.url,
			imageAdsPreviewVisible: true,
		});
	};

	onImageAdsRemove = (type, file) => {
		let imagesAdsNew           = [...this.state.imageAdsList[type]];
		let imageAdsListDeletedNew = [...this.state.imageAdsListDeleted[type]];

		const index = imagesAdsNew.findIndex(item => file.uid === item.uid);

		if( index !== -1 )
		{
			imagesAdsNew.splice(index, 1);

			// Has id
			if( file.id )
			{
				imageAdsListDeletedNew.push(file.id);
			}

			this.setState(state => ({
				imageAdsList       : {
					...state.imageAdsList,
					[type]: imagesAdsNew,
				},
				imageAdsListDeleted: {
					...state.imageAdsListDeleted,
					[type]: imageAdsListDeletedNew,
				},
			}));
		}
	};

	renderAds(type) {
		const imageAdsList = this.state.imageAdsList[type];
		const imageConfig  = config.images["ads"];

		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);

		return (
			<div className="media-images-wrap">
				<Upload
					accept={`.${imageConfig.extensions.join(",.")}`}
					listType="picture-card"
					className="media-images-uploader"
					fileList={imageAdsList}
					onPreview={(file) => this.onImageAdsPreview(type, file)}
					onRemove={(file) => this.onImageAdsRemove(type, file)}
					beforeUpload={(file) => {
						if( !imageConfig.type.includes(file.type) )
						{
							message.error(`Somente são aceitos arquivos ${imageConfig.extensions.join(", ").toUpperCase()}!`);

							return false;
						}

						const isValidSize = file.size / 1024 / 1024 < imageConfig.maxSize;

						if( !isValidSize )
						{
							message.error(`A imagem não pode ultrapassar o tamanho de ${imageConfig.maxSize}MB!`);

							return false;
						}

						let reader    = new FileReader();
						reader.onload = (e) => {
							let imagesAdsNew = [...this.state.imageAdsList[type]];

							if( imagesAdsNew.length < imageConfig.maxFiles )
							{
								// Base64
								file.url = e.target.result;

								imagesAdsNew.push(file);

								this.setState(state => ({
									imageAdsList: {
										...state.imageAdsList,
										[type]: imagesAdsNew,
									},
								}));
							}
						};

						reader.readAsDataURL(file);

						return false;
					}}>
					{imageAdsList.length >= imageConfig.maxFiles ? null : uploadButton}
				</Upload>
			</div>
		);
	}

    renderImages(type, name) {
        const imageList = this.state.imageList[type];
        const imageConfig = config.images[type];

        const uploadButton = (
            <div>
                <Icon type="plus"/>
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        return (
            <div className="media-images-wrap">
                <Upload
                    accept={`.${imageConfig.extensions.join(",.")}`}
                    listType="picture-card"
                    className="media-images-uploader"
                    fileList={imageList}
                    onPreview={(file) => this.onImagePreview(type, file)}
                    onRemove={(file) => this.onImageRemove(type, file)}
                    multiple={imageConfig.maxFiles === 0}
                    beforeUpload={(file) => {
                        if (!imageConfig.type.includes(file.type)) {
                            message.error(`Somente são aceitos arquivos ${imageConfig.extensions.join(", ").toUpperCase()}!`);

                            return false;
                        }

                        const isValidSize = file.size / 1024 / 1024 < imageConfig.maxSize;

                        if (!isValidSize) {
                            message.error(`A imagem não pode ultrapassar o tamanho de ${imageConfig.maxSize}MB!`);

                            return false;
                        }

                        let reader = new FileReader();
                        reader.onload = (e) => {
                            let imagesNew = [...this.state.imageList[type]];

                            if (imageConfig.maxFiles === 0 || imagesNew.length < imageConfig.maxFiles) {
                                // Base64
                                file.url = e.target.result;

                                imagesNew.push(file);

                                this.setState(state => ({
                                    imageList: {
                                        ...state.imageList,
                                        [type]: imagesNew,
                                        [name]: imagesNew,
                                    },
                                }));
                            }
                        };

                        reader.readAsDataURL(file);

                        return false;
                    }}>
                    {imageConfig.maxFiles > 0 && imageList.length >= imageConfig.maxFiles ? null : uploadButton}
                </Upload>
            </div>
        );
    }


	// Button to upload PDF
	renderUpload(type) {
		const tiposAceitos = ['pdf', 'mp3', 'mp4'];
		if (!tiposAceitos.includes(type)) {
			return <>Formato <strong>{type}</strong> não encontrado. Formatos aceitos {tiposAceitos.join(', ')}</>
		}
		const file = this.state.file[type] || [];

		return (
			<Upload
				accept={`.${type.toLowerCase()}`}
				fileList={file}
				onRemove={(file) => {
					this.setState(state => ({
						file: {
							...state.file,
							[type]: [],
						},
					}));
				}}
				beforeUpload={(file) => {
					this.setState(state => ({
						file: {
							...state.file,
							[type]: [file],
						},
					}));

					return false;
				}}>
				{file.length === 0 && <Button>
					<Icon type="upload"/> Selecionar arquivo
				</Button>}
			</Upload>
		);
	}

	render() {
		const {visible, form}                                                    = this.props;
		const {
			id,
			isLoading,
			isSending,
			imagePreviewVisible,
			imagePreviewImage,
			imageAdsPreviewVisible,
			imageAdsPreviewImage,
			publicationTypes,
			activePublicationType
		} = this.state;

		const {getFieldDecorator} = form;

		return (
			<Drawer
				visible={visible}
				className="drawer-form"
				width={500}
				maskClosable={false}
				closable={false}
				keyboard={false}
				placement="right"
				onClose={this.onClose}>
				<Form layout="vertical" onSubmit={this.onSubmit}>
					<div className="form-header">
						<Button className="btn-close" onClick={this.onClose} icon="close" disabled={isLoading || isSending} />
						<div className="ant-drawer-title">{`Editar registro [${id}]`}</div>
						<Button type="primary" htmlType="submit" className="btn-save" icon="check" loading={isSending} disabled={isLoading}>Salvar</Button>
					</div>
					{isLoading ? (
						<div className="text-center" style={{padding: 20}}>
							<Spin indicator={<Icon type="loading" style={{fontSize: 60}} spin />} />
						</div>
					) : (
						<div className="form-body">
							<FormItem label={this.fieldOptions.publication_type.label} hasFeedback>
								{getFieldDecorator(
									"publication_type",
									this.fieldOptions.publication_type.decorator
								)(
									<Select
									>
										{publicationTypes && publicationTypes.map(item => (
											<Select.Option key={item.id} value={item.id}>{item.description}</Select.Option>
										))}
									</Select>
								)}
							</FormItem>
							{activePublicationType.id && <>
								{activePublicationType.author > 0 &&
									<FormItem label={this.fieldOptions[activePublicationType.id.toString()].author.label} hasFeedback>
										{getFieldDecorator(
											"author",
											this.fieldOptions[activePublicationType.id.toString()].author.decorator
										)(<Input/>)}
									</FormItem>
								}
								{activePublicationType.title > 0 &&
									<FormItem label={this.fieldOptions[activePublicationType.id.toString()].title.label} hasFeedback>
										{getFieldDecorator(
											"title",
											this.fieldOptions[activePublicationType.id.toString()].title.decorator
										)(<Input/>)}
									</FormItem>
								}
								{activePublicationType.text > 0 &&
									<FormItem label={this.fieldOptions[activePublicationType.id.toString()].text.label} hasFeedback>
										{getFieldDecorator(
											"text",
											this.fieldOptions[activePublicationType.id.toString()].text.decorator
										)(
											<TextEditor/>
										)}
									</FormItem>
								}
								{activePublicationType.images > 0 &&
									<FormItem label="Imagens" required={activePublicationType.images === 1}>
										{this.renderImages("files", 'images')}
									</FormItem>
								}
								{activePublicationType.thumbnail > 0 &&
									<FormItem label="Thumbnail" required={<activePublicationType className="thumbnailx"></activePublicationType> === 1}>
										{this.renderImages("file", 'thumbnail')}
									</FormItem>
								}
								{activePublicationType.ads > 0 &&
									<FormItem label="Ads" required={activePublicationType.ads === 1}>
										{this.renderAds("images")}
										<small>Dimensões: (940x780px)</small>
									</FormItem>
								}
								{activePublicationType.banner_top > 0 &&
									<FormItem label="Banner Topo" required={activePublicationType.banner_top === 1}>
										{this.renderImages("file", 'banner_top')}
									</FormItem>
								}
								{activePublicationType.banner_foot > 0 &&
									<FormItem label="Banner Rodapé" required={activePublicationType.banner_foot === 1}>
										{this.renderImages("file", 'banner_foot')}
									</FormItem>
								}
								{activePublicationType.pdf > 0 &&
									<FormItem label="Pdf" required={activePublicationType.pdf === 1}>
										{this.renderUpload("pdf")}
									</FormItem>
								}
								{activePublicationType.video > 0 &&
									<FormItem label="Vídeo" required={activePublicationType.video === 1}>
										{this.renderUpload("mp4")}
									</FormItem>
								}
								{activePublicationType.audio > 0 &&
									<FormItem label="Áudio" required={activePublicationType.audio === 1}>
										{this.renderUpload("mp3")}
									</FormItem>
								}
								{activePublicationType.link > 0 &&
									<FormItem label={this.fieldOptions[activePublicationType.id.toString()].link.label} hasFeedback>
										{getFieldDecorator(
											"link",
											this.fieldOptions[activePublicationType.id.toString()].link.decorator
										)(<Input/>)}
									</FormItem>
								}
								<FormItem label={this.fieldOptions.is_active.label}>
									{getFieldDecorator(
										"is_active",
										this.fieldOptions.is_active.decorator
									)(<Switch/>)}
								</FormItem>
							</>
							}
						</div>
					)}
				</Form>
				<Modal className="modal-image" visible={imagePreviewVisible} footer={null} destroyOnClose={true} onCancel={this.onImagePreviewClose}>
					<img src={imagePreviewImage} />
				</Modal>
				<Modal className="modal-image" visible={imageAdsPreviewVisible} footer={null} destroyOnClose={true} onCancel={this.onImageAdsPreviewClose}>
					<img src={imageAdsPreviewImage} />
				</Modal>
			</Drawer>
		)
	}
}

export default Form.create({
	validateMessages: FORM_VALIDATION_MESSAGES,
})(Edit);

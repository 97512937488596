import React, { Component } from "react";
import * as PropTypes from "prop-types";
import {Button, Col, Drawer, Form, Icon, InputNumber, Modal, Row, Select, Spin, Switch, TimePicker} from "antd";

import moment from "moment";

import { pushGeneralService } from "./../../redux/services";

const FormItem = Form.Item;

const repeatTypes = [
	{
		key: 2,
		value: 'Mensalmente',
	},
	{
		key: 1,
		value: 'Semanalmente',
	},
	{
		key: 0,
		value: 'Diáriamente',
	},
];

const weekDays = [
	{
		key: 1,
		value: 'Segunda-Feira',
	},
	{
		key: 2,
		value: 'Terça-Feira',
	},
	{
		key: 3,
		value: 'Quarta-Feira',
	},
	{
		key: 4,
		value: 'Quinta-Feira',
	},
	{
		key: 5,
		value: 'Sexta-Feira',
	},
	{
		key: 6,
		value: 'Sábado',
	},
	{
		key: 0,
		value: 'Domingo',
	},
];

class Show extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			id       : 0,
			item     : {},
		};
	}

	fieldOptions = {
		title       : {
			label: "Título",
		},
		body        : {
			label: "Mensagem",
		},
		url         : {
			label: "Url",
		},
		total_users : {
			label: "Total de usuários",
		},
		scheduled_at: {
			label: "Agendado para",
		},
		repeat : {
			label	 : "Repetir",
		},
		repeatType : {
			label	: "Qual frequência?",
		},
		hour : {
			label : "Defina a hora!"
		},
		weekDay : {
			label : "Defina o dia da semana!"
		},
		day : {
			label : "Defina o dia!"
		},
		send_at     : {
			label: "Enviado em",
		},
		created_at  : {
			label: "Criação",
		},
	};

	onOpen = (id) => {
		this.setState({
			isLoading: true,
			id       : id,
			item     : {},
		});

		pushGeneralService.show({id})
		.then((response) => {
			this.setState({
				isLoading: false,
				item     : response.data.data,
			});
		})
		.catch((data) => {
			this.setState({
				isLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: data.error_message,
				onOk   : () => {
					// Force close
					return this.onClose();
				}
			});
		});
	};

	resetFields = () => {
		this.setState({
			item: {},
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	render() {
		const {visible}             = this.props;
		const {id, isLoading, item} = this.state;

		return (
			<Drawer
				visible={visible}
				className="drawer-form"
				width={360}
				maskClosable={false}
				closable={false}
				keyboard={false}
				placement="right"
				onClose={this.onClose}>
				<Form layout="vertical">
					<div className="form-header">
						<Button className="btn-close" onClick={this.onClose} icon="close" disabled={isLoading} />
						<div className="ant-drawer-title">{`Visualizar registro [${id}]`}</div>
					</div>
					{isLoading ? (
						<div className="text-center" style={{padding: 20}}>
							<Spin indicator={<Icon type="loading" style={{fontSize: 60}} spin />} />
						</div>
					) : (
						<div className="form-body">
							<FormItem label={this.fieldOptions.title.label}>
								{item.title}
							</FormItem>
							<FormItem label={this.fieldOptions.body.label}>
								{item.body}
							</FormItem>
							<FormItem label={this.fieldOptions.total_users.label}>
								{item.total_users}
							</FormItem>
							<FormItem label={this.fieldOptions.url.label}>
								{item.url}
							</FormItem>
							<FormItem label={this.fieldOptions.scheduled_at.label}>
								{item.scheduled_at && moment(item.scheduled_at).calendar()}
							</FormItem>
							<Row gutter={16}>
								<Col xs={12}>
									<FormItem label={this.fieldOptions.repeat.label}>
										<Switch disabled checked={item.repeat} />
									</FormItem>
								</Col>
								{item.repeat &&
									<Col xs={12}>
										<FormItem label={this.fieldOptions.repeatType.label}>
											{repeatTypes.find(rt => rt.key === item.repeatType)?.value ?? ''}
										</FormItem>
									</Col>
								}
							</Row>
							{item.repeat &&
							<Row gutter={26}>
								{item.repeatType == 1 &&
									<Col xs={13}>
										<FormItem label={this.fieldOptions.weekDay.label} hasFeedback>
											{weekDays.find(wd => wd.key === item.weekDay).value || ''}
										</FormItem>
									</Col>
								}
								{item.repeatType == 2 &&
									<Col xs={13}>
										<FormItem label={this.fieldOptions.day.label} hasFeedback>
											{item.day}
										</FormItem>
									</Col>
								}
								<Col xs={11}>
									<FormItem label={this.fieldOptions.hour.label}>
										{item.hour}
									</FormItem>
								</Col>
							</Row>
							}
							<FormItem label={this.fieldOptions.send_at.label}>
								{item.send_at && moment(item.send_at).calendar()}
							</FormItem>
							<FormItem label={this.fieldOptions.created_at.label}>
								{moment(item.created_at).calendar()}
							</FormItem>
						</div>
					)}
				</Form>
			</Drawer>
		)
	}
}

export default Show;
